import * as auth0 from 'auth0-js';
import {v4 as uuidv4} from 'uuid';

import {
  Authorized,
  EnterCodeScreen,
  Screen,
  VerificationMethod,
  EntryScreen,
  EnterEmailScreen,
} from './authApi';
import * as appCfg from '../config';

const MOBILE_NATIVE_REDIRECT_PREFIX = 'com.ennabl.auth0://';
const DEFAULT_AUTHORIZED_URI = `${appCfg.BASE_URL}/?authorized=true`;

const params = new URLSearchParams(window.location.search);

const nonceFromParams = params.get('nonce') || undefined;
const clientIdFromParams = params.get('client_id') || undefined;
const emailFromParams =
  params.get('email') || params.get('ennabl_auth_email') || undefined;
const stateFromParams =
  params.get('source_state') || params.get('state') || undefined;
  const redirectUriFromParams =
  params.get('redirectUri') || params.get('redirect_uri') || undefined;
  
  const state = stateFromParams || uuidv4();
  const nonce = nonceFromParams || uuidv4();
const authorized = params.get('authorized') || 'false';
const forceLogout = params.get('logout') || 'false';
const scope = params.get('scope') ?? 'openid profile email';
const redirectUri =
  redirectUriFromParams || appCfg.DEFAULT_APP_AUTHORIZED_REDIRECT_URI;

const isMobileNative = redirectUri.startsWith(MOBILE_NATIVE_REDIRECT_PREFIX);

const shouldRedirectBack =
  !isMobileNative &&
  (!stateFromParams || !clientIdFromParams || !nonceFromParams);

const authProps: auth0.AuthOptions = {
  clientID: appCfg.AUTH0_CLIENT_ID,
  domain: appCfg.AUTH0_DOMAIN,
  responseType: 'code',
  state,
  audience: appCfg.AUTH0_AUDEINCE,
  scope,
  redirectUri: stateFromParams ? redirectUri : DEFAULT_AUTHORIZED_URI,
};

const webAuth = new auth0.WebAuth(authProps);

export function entryScreen(isSignUp = false): EntryScreen | Authorized {
  if (authorized === 'true' && window.location.href !== redirectUri) {
    window.location.replace(redirectUri);
    return {
      screenType: 'AUTHORIZED',
    };
  }

  if (isSignUp) {
    return {
      screenType: 'ENTRY_SCREEN',
      start: startSignup,
    };
  } else {
    return {
      screenType: 'ENTRY_SCREEN',
      start: startAuth,
    };
  }
}

export async function startAuth(): Promise<Screen> {
  if (forceLogout === 'true') {
    await logout();
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  // Fire and forget
  getTokenDataOrNull().then(token => {
    if (token) {
      console.log('🚀 ~ token:', token)
      window.location.replace(redirectUri);
      return {
        screenType: 'AUTHORIZED',
      };
    }
  });

  if (shouldRedirectBack) {
    window.location.replace(redirectUri);
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  return goToEnterEmailScreen({
    method: 'EMAIL',
    device: {
      deviceStatus: 'KNOWN_DEVICE',
    },
  });
}

export async function startSignup(): Promise<Screen> {
  if (!emailFromParams) {
    window.location.replace(window.location.origin);
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  if (shouldRedirectBack) {
    const url = new URL(redirectUri);
    url.searchParams.set('ennabl_auth_signup', 'true');
    url.searchParams.set('ennabl_auth_email', emailFromParams);

    window.location.replace(url.toString());
    return {
      screenType: 'ENTRY_SCREEN',
    };
  }

  const ctx: Ctx = {
    device: {
      deviceStatus: 'UNKNOWN_DEVICE',
      email: emailFromParams,
    },
  };

  return goToEnterCodeScreen({
    ...ctx,
    method: 'EMAIL',
  });
}

export async function CreateUser() {
  return async () => {
    try {
      const token = await getToken();
      const response = await signup(token);
      if (response.status === 403) {
        const responseJson = await response.json();
        if (responseJson) throw responseJson;
        else throw await response.text();
      }

      window.location.replace(redirectUri);
      return {
        screenType: 'AUTHORIZED',
      };
    } catch (e: any) {
      if (e.error_description) throw e.error_description;
      else throw e;
    }
  };
}

async function getTokenDataOrNull() {
  try {
    return await getToken();
  } catch (e: any) {
    return null;
  }
}

async function getToken(): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    webAuth.checkSession(
      {
        responseType: 'token',
        nonce,
        state,
      },
      (error, result) => {
        if (error) {
          return reject(error);
        }
        return resolve(result.accessToken);
      }
    );
  });
}

interface Ctx {
  device: DeviceInfo;
  method?: VerificationMethod;
  errorMessage?: string;
}

async function goToEnterCodeScreen(
  ctx: Ctx,
  backScreen?: Promise<Screen>
): Promise<EnterCodeScreen> {
  let connection: string;
  let authInfo: {email?: string};
  if (ctx.method === 'EMAIL') {
    connection = 'email';
    authInfo = {email: ctx.device.email!};
  } else {
    throw new Error(`Unknown verification method: ${ctx.method}`);
  }

  let authParams: any = {state: stateFromParams, nonce};

  const sendCode = async (ctx: Ctx) => {
    return new Promise<EnterCodeScreen>((resolve, reject) => {
      const enterCodeScreen: EnterCodeScreen = {
        screenType: 'ENTER_CODE',
        backScreen,
        method: ctx.method!,
        ...authInfo,
        error: ctx.errorMessage,
        enter,
        resend: () => sendCode(ctx),
      };

      if (isPasswordLessAuth(ctx.device.email)) {
        webAuth.passwordlessStart(
          {
            connection,
            authParams,
            send: 'code',
            ...authInfo,
          },
          error => {
            if (error) {
              return reject(error);
            }
            return resolve(enterCodeScreen);
          }
        );
      } else {
        resolve(enterCodeScreen);
      }
    });
  };

  const enter = async (code: string) => {
    return new Promise<Screen>((resolve, reject) => {
      const onErrorCallback = (error: auth0.Auth0Error | null) => {
        if (error) {
          reject(error.error_description);
          return resolve({screenType: 'AUTH_FAILED'});
        }
        return resolve({screenType: 'AUTHORIZED'});
      };

      if (isPasswordLessAuth(ctx.device.email)) {
        webAuth.passwordlessLogin(
          {
            connection,
            verificationCode: code,
            ...authInfo,
            nonce,
          },
          onErrorCallback
        );
      } else {
        webAuth.login(
          {
            email: ctx.device.email!,
            password: code,
            realm: 'Username-Password-Authentication',
            nonce,
          },
          onErrorCallback
        );
      }
    });
  };

  return sendCode(ctx);
}

async function goToEnterEmailScreen(ctx: Ctx): Promise<EnterEmailScreen> {
  return {
    screenType: 'ENTER_EMAIL_SCREEN',
    login: email =>
      goToEnterCodeScreen({
        ...ctx,
        device: {
          email,
          deviceStatus: 'KNOWN_DEVICE',
        },
      }),
  };
}

interface DeviceInfo {
  deviceStatus: 'KNOWN_DEVICE' | 'UNKNOWN_DEVICE';
  email?: string;
  phone?: string;
  name?: string;
  lastVerificationMethod?: 'SMS' | 'EMAIL';
}

export async function logout() {
  await forgetDevice();
  webAuth.logout({});
}

export async function forgetDevice() {
  await fetch(appCfg.APP_LOGOUT_URL, {
    credentials: 'include',
  });
}

export async function resetPassword(email: string): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    webAuth.changePassword(
      {
        connection: appCfg.AUTH0_DB_CONNECTION,
        email,
      },
      error => {
        if (error) {
          return resolve(false);
        }
        return resolve(true);
      }
    );
  });
}

async function signup(token: string): Promise<Response> {
  return await fetch(`${appCfg.APP_SIGNUP_URI}?state=${state}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });
}

export function isPasswordLessAuth(email: string | null | undefined): boolean {
  return !(email ?? '').endsWith('@ennabl-test.com');
}
