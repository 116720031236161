export const BASE_URL = 'https://login.ennabl.com';

export const AUTH0_AUDEINCE = 'https://api.ennabl.com';
export const AUTH0_DOMAIN = 'auth.ennabl.com';
export const AUTH0_CLIENT_ID = 'AFSyHTUqNsLifr2N14OvKeuOM0TRoDYP';
export const AUTH0_DB_CONNECTION = 'Username-Password-Authentication';

export const DEFAULT_APP_AUTHORIZED_REDIRECT_URI = `https://app.ennabl.com/`;
export const APP_PREVIEW_AUTHORIZED_REDIRECT_URI = `https://preview.ennabl.com/`;
export const APP_LOGOUT_URL = 'https://api.ennabl.com/logout';
export const APP_SIGNUP_URI = 'https://api.ennabl.com/signup';
